import { useStaticQuery, graphql } from "gatsby";
export const useGetMenu = () => {
  const menu = useStaticQuery(
    graphql`
      query getMenu {
        wp {
          menu(idType: NAME, id: "Main menu") {
            id
            name
            menuItems {
              nodes {
                label
                path
                id
              }
            }
          }
        }
      }
    `
  );
  return menu.wp.menu;
};
