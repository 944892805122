import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Obfuscate from "react-obfuscate";

const LinkButton = ({ link, label, color, email }) => {
  if (email) {
    return (
      <Obfuscate
        className={`flex border-2 rounded-2xl px-4 py-1 text-sm justify-between items-center max-w-[200px] ${
          color === "blue"
            ? "text-cerulean border-cerulean hover:text-white"
            : " hover:text-cerulean-900"
        }`}
        email={link}
      />
    );
  }
  return (
    <a
      href={link}
      className={`group flex border-2 btn overflow-hidden transition-all duration-200 relative rounded-2xl px-4 py-1 text-sm justify-between items-center max-w-[250px] ${
        color === "blue"
          ? "text-cerulean border-cerulean hover:text-white"
          : "hover:text-cerulean-900"
      }`}
    >
      <span
        className={`link-bg absolute transition-all duration-200 -left-full h-full w-full group-hover:left-0 ${
          color === "blue" ? "bg-cerulean" : "bg-white"
        }`}
      ></span>
      <span className="z-10 transition duration-300 flex justify-between w-full">
        {label}
        <BsArrowRight className="text-xl" />
      </span>
    </a>
  );
};

export default LinkButton;
