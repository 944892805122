import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import { HamburgerSqueeze } from "react-animated-burgers";

import { GetFooterData } from "../queries/footerdata";

import { useGetMenu } from "../queries/menu";
import LogoLight from "../images/logo.svg";
import LogoDark from "../images/logoDark.svg";

function Header({ themeStyle }) {
  const menu = useGetMenu();
  const menuItems = menu.menuItems.nodes;
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(true);
  let [isLight, setIsLight] = useState(themeStyle);

  const socialdata = GetFooterData();
  const { twitterLink } = socialdata.wp.themeGeneralSettings.globalOptions;
  const { linkedInLink } = socialdata.wp.themeGeneralSettings.globalOptions;

  const showMenu = () => {
    setIsOpen(!isOpen);
    setIsLight(!isLight);
  };
  useEffect(() => {
    window.onscroll = () => {
      let scrollCheck = window.scrollY < 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
        if (themeStyle) {
          setIsLight(!isLight);
        }
      }
    };
  });

  const closeMenu = () => {
    if (themeStyle) {
      setIsOpen(false);
      setScroll(!scroll);
      setIsLight(!isLight);
    } else {
      setIsOpen(false);
      setScroll(true);
      setIsLight(false);
    }
  };

  return (
    <div
      className={`fixed transition duration-300 w-full z-50 top-0 ${
        isLight ? "bg-transparent" : "bg-white"
      }`}
    >
      <nav
        className={` transition-all duration-300 py-14 lg:py-12 pl-8 lg:pl-10 mx-auto 2xl:container  ${
          scroll ? "" : "py-12 lg:py-8"
        }`}
      >
        <div className="absolute z-0 top-0 w-full h-full"></div>
        <div className="lg:hidden">
          <HamburgerSqueeze
            className={`z-20 transition-all duration-[300ms!important] absolute top-8 right-8 lg:top-12 lg:right-10 ${
              scroll ? "" : "top-5 lg:top-12"
            }`}
            onClick={showMenu}
            buttonWidth={40}
            buttonHeight={33}
            isActive={isOpen}
            barColor={`${isLight ? "white" : isOpen ? "white" : "#04204C"}`}
          />
        </div>
        <Link
          className={`absolute transition-all duration-300 z-20 top-8 lg:top-12 ${
            scroll ? "" : "top-5 lg:top-6"
          }`}
          to="/"
        >
          <img
            className={`w-[133px] h-[59px] transition-all duration-300 ${
              scroll ? "lg:w-[197px] lg:h-[87px]" : ""
            }`}
            width={197}
            height={87}
            src={isLight ? LogoLight : isOpen ? LogoLight : LogoDark}
            alt={`site logo`}
          />
        </Link>
        <div
          className={`absolute overflow-scroll	lg:overflow-auto lg:py-0 left-0 bg-cerulean-900 lg:bg-transparent transition-all duration-700 ease-in-out text-white h-screen lg:h-auto w-screen lg:relative z-10 container flex justify-between lg:justify-end ${
            isOpen ? "top-0" : "top-[-100vh] lg:top-0"
          }`}
        >
          <div
            className={`flex items-start flex-col justify-center lg:justify-end lg:flex-row gap-4 text-white w-full lg:w-auto 2xl:mr-8 ${
              isLight ? "lg:text-white" : "lg:text-black"
            }`}
          >
            {/* Nav */}

            {menuItems && (
              <div className="flex lg:block flex-col w-full pl-12 short-nav lg:pl-0 lg:w-auto items-start justify-center">
                {menuItems.map((item) => {
                  return (
                    <Link
                      activeClassName={`underline`}
                      partiallyActive={true}
                      key={item.id}
                      to={item.path}
                      className="px-4 py-2 mb-8 lg:mb-0 inline-block uppercase text-3xl lg:text-sm"
                      onClick={closeMenu}
                    >
                      {item.label}
                    </Link>
                  );
                })}
              </div>
            )}
            {/* Social */}

            <div className="flex space-x-6 absolute left-12 bottom-12 short-nav-icons lg:relative lg:bottom-auto lg:left-auto lg:space-x-4 lg:w-auto items-center justify-start lg:justify-center py-2">
            {twitterLink && (
              <span
                className={`w-6 h-6 text-3xl text-cerulean transition duration-300  ${
                  isLight
                    ? "lg:text-white hover:text-cerulean"
                    : "lg:text-black hover:text-cerulean"
                } lg:text-lg`}
              >
                <a target="_blank" rel="noreferrer" href={twitterLink}>
                  <FaTwitter />
                </a>
              </span>
              )}

              {linkedInLink && (
              <span
                className={`w-6 h-6 text-3xl text-cerulean transition duration-300  ${
                  isLight
                    ? "lg:text-white hover:text-cerulean"
                    : "lg:text-black hover:text-cerulean"
                } lg:text-lg`}
              >
                <a target="_blank" rel="noreferrer" href={linkedInLink}>
                  <FaLinkedin />
                </a>
              </span>
                )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
