import { useStaticQuery, graphql } from "gatsby";
export const GetFooterData = () => {
  const footerdata = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            description
            siteUrl
            title
          }
        }
        wp {
          themeGeneralSettings {
            globalOptions {
              contactDetails {
                mobile
                name
                title
              }
              emailAddress
              footerConsentLabel
              linkedInLink
              twitterLink
            }
          }
        }
      }
    `
  );
  return footerdata;
};
