import React from "react";
import Helmet from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
// import CookieConsent from "react-cookie-consent";
// import { useLocation } from "@reach/router";
// import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { GetMetaData } from "../queries/metadata";

const Layout = ({ title, page, children, themeStyle }) => {
  const metadata = GetMetaData();
  // const location = useLocation();
  // const data = useStaticQuery(graphql`
  //   {
  //     site {
  //       siteMetadata {
  //         description
  //         siteUrl
  //         title
  //       }
  //     }
  //     # wp {
  //     #   themeGeneralSettings {
  //     #     globalOptions {
  //     #       cookieBanner {
  //     #         content
  //     #         acceptButton
  //     #       }
  //     #     }
  //     #   }
  //     # }
  //   }
  // `);
  // const cookieText =
  //   data.wp.themeGeneralSettings.globalOptions.cookieBanner.content;
  // const cookieButtonText =
  //   data.wp.themeGeneralSettings.globalOptions.cookieBanner.acceptButton ||
  //   "I understand";
  return (
    <>
      <Helmet
        title={title}
        defer={false}
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta name="icon" href="/images/favicon.svg" />
        <meta
          name="description"
          content={metadata.site.siteMetadata.description}
        />
      </Helmet>
      <Header themeStyle={themeStyle} />
      {children}
      <Footer page={page} />
      {/* <CookieConsent
        buttonText={cookieButtonText}
        cookieName="gatsby-gdpr-google-tagmanager"
        onAccept={() => {
          initializeAndTrack(location);
        }}
        disableStyles={true}
        buttonClasses="px-4 py-1 border-white border-2 border-opacity-10 rounded-lg mr-4 transition duration-200 hover-hover:hover:bg-white hover-hover:hover:text-purple"
        declineButtonClasses="px-4 py-1 border-white border-2 border-opacity-10 rounded-lg transition duration-200 hover-hover:hover:bg-white hover-hover:hover:text-purple"
        containerClasses="fixed bottom-0 z-50 w-full py-8 px-16 bg-purple bg-opacity-90 backdrop-filter backdrop-blur-md text-white flex justify-between items-center"
        contentClasses="text-lg md:text-xl"
      >
        <div dangerouslySetInnerHTML={{ __html: cookieText }}></div>
      </CookieConsent> */}
    </>
  );
};

export default Layout;
