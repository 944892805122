import React from "react";
import { Link } from "gatsby";
import { FaTwitter, FaLinkedin } from "react-icons/fa";
import LinkButton from "./LinkButton";
import { GetFooterData } from "../queries/footerdata";

const Footer = () => {
  const data = GetFooterData();

  const { contactDetails } = data.wp.themeGeneralSettings.globalOptions;
  const { emailAddress } = data.wp.themeGeneralSettings.globalOptions;
  // const { footerConsentLabel } = data.wp.themeGeneralSettings.globalOptions;
  const { twitterLink } = data.wp.themeGeneralSettings.globalOptions;
  const { linkedInLink } = data.wp.themeGeneralSettings.globalOptions;
  const { siteMetadata } = data.site;
  return (
    <footer
      className="bg-cerulean-900 text-white px-12 md:px-28 py-16"
      id="contact"
    >
      <div className="container">
        <div className="text-5xl leading-tight font-bold uppercase text-cerulean mb-8">
          Get in touch
        </div>
      </div>
      <div className="container flex justify-between flex-wrap relative">
        <div className="mb-16 w-full md:w-auto md:mb-0">
          <div className="space-y-2 mb-12">
            {contactDetails &&
              contactDetails.map((contact, i) => {
                return (
                  <div key={i} className="">
                    <strong>{contact.name}</strong> {contact.title}
                    <div>m. {contact.mobile}</div>
                  </div>
                );
              })}
          </div>
          <div className="flex justify-between md:hidden w-full">
            <LinkButton label={`Email us`} link={`mailto:${emailAddress}`} />
            <div className="space-x-6 flex">
            {twitterLink && (
              <span
                className={`w-6 h-6 text-3xl text-cerulean transition duration-300 hover:text-white`}
              >
                <a target="_blank" rel="noreferrer" href={twitterLink}>
                  <FaTwitter />
                </a>
              </span>
            )}
            {linkedInLink && (
              <span
                className={`w-6 h-6 text-3xl text-cerulean transition duration-300 hover:text-white`}
              >
                <a target="_blank" rel="noreferrer" href={linkedInLink}>
                  <FaLinkedin />
                </a>
              </span>
            )}
            </div>
          </div>
          <div className="hidden lg:block mt-12 space-x-6">
            <span>&copy; {siteMetadata.title} </span>
            <Link className="underline" to="/privacy-policy">
              Privacy Policy
            </Link>
            {/* <Link className="underline" to="/terms-of-business">
              Terms of Business
            </Link> */}
          </div>
        </div>

        <div className="md:flex md:h-full flex-col items content-center">
          {/* <p className="mb-4 font-bold text-cerulean text-xl">
            Hear more from us
          </p>
          <form action="" className="flex flex-col space-y-6">
            <div className="flex flex-wrap space-y-6 md:space-y-0 md:space-x-6">
              <div className="flex items-end space-x-6">
                <label className="relative -bottom-1" htmlFor="name">
                  Name
                </label>
                <input
                  className="bg-transparent border-b-2 border-white"
                  id="name"
                  type="text"
                />
              </div>
              <div className="flex items-end space-x-6">
                <label className="relative -bottom-1" htmlFor="email">
                  Email
                </label>
                <input
                  className="bg-transparent border-b-2 border-white"
                  id="email"
                  type="text"
                />
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <input type="checkbox" id="consentcheck" />
              <label htmlFor="consentcheck">{footerConsentLabel}</label>
            </div>
            <LinkButton label={`Sign up`} link={`mailto:${emailAddress}`} />
          </form> */}
        </div>

        <div className="space-x-6 absolute right-0 bottom-0 hidden md:flex">
        {twitterLink && (
          <span
            className={`w-6 h-6 text-3xl text-cerulean transition duration-300 hover:text-white`}
          >
            <a target="_blank" rel="noreferrer" href={twitterLink}>
              <FaTwitter />
            </a>
          </span>
          )}
          {linkedInLink && (
          <span
            className={`w-6 h-6 text-3xl text-cerulean transition duration-300 hover:text-white`}
          >
            <a target="_blank" rel="noreferrer" href={linkedInLink}>
              <FaLinkedin />
            </a>
          </span>
            )}
        </div>
        <div className="lg:hidden mt-12">
          <span className="inline-block mb-3">
            &copy; {siteMetadata.title}{" "}
          </span>
          <br />
          <Link className="underline mr-4" to="/privacy-policy">
            Privacy Policy
          </Link>
          {/* <Link className="underline" to="/terms-of-business">
            Terms of Business
          </Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
