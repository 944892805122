import { useStaticQuery, graphql } from "gatsby";
export const GetMetaData = () => {
  const metadata = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            description
            siteUrl
            title
          }
        }
        # wp {
        #   themeGeneralSettings {
        #     globalOptions {
        #       cookieBanner {
        #         content
        #         acceptButton
        #       }
        #     }
        #   }
        # }
      }
    `
  );
  return metadata;
};
